import React from "react";
import Sideber from "./Sidebar";
import Header from "./Header";

import selectoption from "../image/capage/4C9F1DFE-3D54-4D0E-8ACE-B0A0E1E3AF7E.png";
import Switch from "@material-ui/core/Switch";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Scrollbars} from "react-custom-scrollbars";

import inter from "../image/internet.svg";

import Token1 from "../image/toker1.png";
import {Button} from "@material-ui/core";

import Footer from "./Footer";

function Home() {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  return (
    <ScrollingProvider>
      <div className="main_pading">
        <Header />

        <section className="craetesingle">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="create_single">
                  <div className="">
                    <div className="single_title_section">
                      <h1>
                        Create single <br />
                        collectible
                      </h1>
                      <Button className="nutton_choose_mul">
                        Switch to Multiple
                      </Button>
                    </div>
                  </div>

                  <div className="form_section">
                    <div className="input_section mb-3">
                      <label>Category</label>
                      <div>
                        <select>
                          <option>Art</option>
                          <option>Audio</option>

                          <option>Video</option>
                        </select>
                      </div>
                    </div>
                    <div className="input_section mb-3">
                      <p className="headein">Upload Files</p>
                      <label>Drag or choose your file to upload</label>

                      <div className="upload_files">
                        <input type="file" className="file" />
                        <div className="upload_content">
                          <i class="fas fa-upload"></i>
                          <p>PNG, GIF, WEBP, MP4 or MP3. MAX 1GB</p>
                        </div>
                      </div>
                    </div>
                    <div className="input_section mb-3">
                      <p className="headein">Item Details</p>
                      <label>Item Name</label>
                      <div>
                        <input
                          type="text"
                          placeholder="e.g Reddeemable Bitcoin Card with Logo"
                          className="text"
                        />
                      </div>
                    </div>
                    <div className="input_section mb-3">
                      <label>Description</label>
                      <div>
                        <input
                          type="text"
                          placeholder="e.g Reddeemable Bitcoin Card with Logo"
                          className="text"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="input_section mb-3">
                          <label>Royalties</label>
                          <div>
                            <select>
                              <option>Art</option>
                              <option>Audio</option>

                              <option>Video</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_section mb-3">
                          <label>Quantitiy</label>
                          <div>
                            <input
                              type="text"
                              placeholder="e.g Size"
                              className="text w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="put_on_sale mt-3">
                      <div>
                        <p>Put on sale</p>
                        <span className="currr">
                          You'll recieve bid on this item
                        </span>
                      </div>
                      <div>
                        <Switch
                          checked={state.checkedB}
                          onChange={handleChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{"aria-label": "primary checkbox"}}
                        />
                      </div>
                    </div>

                    <div className="crate_btn">
                      <Button className="p-button">
                        Create Mint <i class="fas fa-arrow-right"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 create_single_cardd">
                <div className="refernc_cad">
                  <div className="imge_with_1">
                    <div className="imge_with_2">
                      <img src={selectoption} />
                    </div>
                  </div>
                  <div className="bottom_content">
                    <div className="titirle_ss">
                      <p>Amazing Art</p>
                      <span className="label_card">2.5 ETH</span>
                    </div>
                    <div className="profile_namess">
                      <div className="inner_contenyteee">
                        <img src={selectoption} />
                        <h6>E Cole.</h6>
                      </div>
                      <h5>3 mints left</h5>
                    </div>
                    <div className="final_price">
                      <p>
                        <span>0.03 ETH</span>405
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </ScrollingProvider>
  );
}

export default Home;
