import React, {useState, useEffect} from "react";
import Sideber from "../component/Sidebar";
import Header from "../component/Headerafterlogin";
import HeaderAfter from "../component/Headerafterlogin";

import Twitter from "../image/twitter.svg";
import USD from "../image/USD.png";
import BTC from "../image/BTC.png";
import TRX from "../image/trx.png";
import BTC1 from "../image/btc_1.png";
import TUST from "../image/usdt.png";
import ETH from "../image/eth.png";
import TUSD from "../image/tusd.png";
import TUDC from "../image/usdc.png";
import landing_img from "../image/solaogroup.jpeg";
import homei_1 from "../image/home2_1.png";
import homei_2 from "../image/home2_2.png";

import homei_3 from "../image/home2_3.png";

import homei_4 from "../image/home2_4.png";
import homesectio2 from "../image/capage/D966AB20-7994-4931-8E45-8A582E3F0A77.png";
import Tokenomic from "../image/Tokenomics.png";
import Trade from "../image/Trade.png";

import lion_1 from "../image/lion_img.png";
import facetoken from "../image/tokenface.png";
import filan_icon_1 from "../image/trade_icon.png";
import filan_icon_2 from "../image/earn_icon.png";

import filan_icon_3 from "../image/arb_icon.png";
import link_i from "../image/link_i.png";
import logoN from "../image/clola_logoo.png";
import house_2 from "../image/house_2.png";

import Chart from "./chart/Chart";

import Footer from "./Footer";

import {toastAlert} from "../component/toast";

import waiting from "../image/waiting.png";
import faild from "../image/faild.png";

import sucess from "../image/succes.png";

import ReactTooltip from "react-tooltip";
import MaterialDesignSwitch from "../component/Countdown";

import user1 from "../image/founder.png";
import dedault from "../image/dedault.png";
import YouTube from "react-youtube";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Scrollbars} from "react-custom-scrollbars";
import white from "../image/pdf/Solalgowhite.pdf";

import inter from "../image/internet.svg";

import Token1 from "../image/toker1.png";
import Bgvedio from "../image/video/bannervid.mp4";
import Homevid from "../image/video/home_vis.mp4";

import {Button} from "@material-ui/core";
import pitchdesk from "../image/pdf/Solalgo_Pitch_Deck_v3.pdf";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const axios = require("axios");

function Home() {
  const initialFormValue = {
    email: "",
    userName: "",
    comments: "",
    Subject: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const aboutSection = useScrollSection("about");
  const [ValidateNameError, setValidateNameError] = useState(false);
  const [ValidateEmailError, setValidateEmailError] = useState(false);
  const [ValidateCommentsError, setValidateCommentsError] = useState(false);
  const [validationErrors, setvalidationErrors] = useState("");
  const [validateMobilesError, setValidateMobilesError] = useState(false);
  const [ValidateSubjectError, setValidateSubjectError] = useState(false);

  const {email, userName, comments, Subject} = formValue;

  const handleChangeData = async (e) => {
    try {
      e.preventDefault();
      const {name, value} = e.target;
      let formData = {...formValue, ...{[name]: value}};
      setFormValue(formData);
    } catch (error) {}
  };

  const sendMessage = async () => {
    try {
      validation(formValue);
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)) {
      } else {
        if (
          formValue.email != "" &&
          formValue.comments != "" &&
          formValue.userName != ""
        ) {
          let messageSent = await axios({
            method: "post",
            url: "https://api.solalgo.com/sendMail",
            headers: {"Content-Type": "application/json"},
            data: formValue,
          });
          console.log(
            messageSent.data.status,
            "=-=-=messageSent-=-=-messageSent-=-=-messageSent"
          );
          if (messageSent.data.status) {
            toastAlert("success", messageSent.data.Message);
            setFormValue(initialFormValue);
          } else {
            toastAlert("error", messageSent.data.Message);
          }
        } else {
        }
      }
      console.log("---sendMessage---");
    } catch (error) {}
  };

  const validation = async (validateName) => {
    try {
      const errors = {};
      if (!validateName.userName) {
        setValidateNameError(true);
        errors.userName = "Must Enter Your Name";
      } else {
        setValidateNameError(false);
      }
      if (!validateName.Subject) {
        setValidateSubjectError(true);
        errors.Subject = "Must Enter Subject Name";
      } else {
        setValidateSubjectError(false);
      }
      if (!validateName.email) {
        setValidateEmailError(true);
        errors.email = "Must Enter Email Address";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(validateName.email)
      ) {
        setValidateEmailError(true);
        errors.email = "Invalid Email Address";
      } else {
        setValidateEmailError(false);
      }
      if (!validateName.comments) {
        setValidateCommentsError(true);
        errors.comments = "Must Enter Your Comments";
      } else if (validateName.comments) {
        var textLenghtCheck = validateName.comments.length;
        if (textLenghtCheck > 300) {
          errors.comments = "Only allowed 300 characters. ";
          setValidateCommentsError(true);
        }
      } else {
        setValidateCommentsError(false);
      }
      setvalidationErrors(errors);
    } catch (error) {}
  };

  const addToken = async () => {
    const tokenAddress = "0xf3afb1d8b11c2eaa133511106aa03ae49296976e";
    const tokenSymbol = "CHOLA";
    const tokenDecimals = 18;
    const tokenImage =
      "https://res.cloudinary.com/dpuwta04a/image/upload/v1649527602/clola_logoo.b262654b225cac511164_cjaigc.png";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <ScrollingProvider>
        <Header />
        <main className="over-hinden">
          <Section id="home">
            {/* <video autoplay muted loop id="myVideo">
              <source src={Bgvedio} type="video/mp4" />
            </video> */}
            <video className="videoTag" autoPlay loop muted>
              <source src={Bgvedio} type="video/mp4" />
            </video>

            <section className="heading_banner">
              <div className="container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={8}>
                    <div
                      className="home_content_section"
                      data-aos="fade-up"
                      data-aos-duration="3000"
                    >
                      <h1>THE WORLD'S FIRST WEB3.0 iDIB</h1>
                      <p>(intelligent Digital Investment Bank)</p>
                      {/* <div className="buyicon">
                        <img src={require("../image/Logo-Apple-Pay1.png")} />
                        <img
                          src={require("../image/MasterCard-Logo-1996.png")}
                        />

                        <img src={require("../image/VISA-Logo-1976.png")} />
                      </div> */}
                      <div className="align_ien">
                        {/* <Button className="button_conntect_wal p-3 button_homeee">
                          <a
                            className="colo_0000"
                            href="https://sol.solalgo.com/"
                            target="_blank"
                          >
                            BUY NOW
                          </a>
                        </Button> */}
                        <div className="slgo_link butnr" >
                          <Button className="button_conntect_wal p-3">
                            <a
                              className="colo_0000"
                              href={pitchdesk}
                              target="_blank"
                            >
                              Pitch Deck
                            </a>
                          </Button>

                          <Button className="button_conntect_wal p-3">
                            <a
                              className="colo_0000"
                              href={white}
                              target="_blank"
                            >
                              whitepaper
                            </a>
                          </Button>

                          <Button className="button_conntect_wal p-3">
                            <a
                              className="colo_0000"
                              href="https://bscscan.com/token/0x940580db429da7fa8662d66f7a4d312443f09f52"
                              target="_blank"
                            >
                              Smart Contract
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <div className="icon_auditedd">
                      <a
                        href="https://github.com/coinscope-co/kyc/blob/main/slgo/kyc.png"
                        target="_blank"
                      >
                        <img src={require("../image/teamcheck.png")} />
                      </a>
                      <a
                        href="https://github.com/ContractChecker/audits/blob/main/Solalgo_Token_Smart_Contract_Security_Audit_By_ContractChecker.pdf"
                        target="_blank"
                      >
                        <img src={require("../image/Smartcheck.png")} />
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>

          <Section id="Mission">
            <section id="Mission" className="starbg">
              <div className="container pading">
                <Grid container spacing={2}>
                  <h1 className="title_sect gradion_text" data-aos="fade-up">
                    THE SOLALGO GROUP
                  </h1>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      className="section_3_content"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      {/* <p>
                        <strong className="mr-2">Investing</strong>in any
                        businesses – particularly start-ups – comes with a
                        degree of risk,{" "}
                        <i>
                          (couple that with a brave new world of cryptocurrency,
                          and the potential for success requires a very
                          carefully considered innovative approach).
                        </i>
                      </p> */}
                      <p>
                        <i>THE FUTURE OF CRYPTO TODAY</i>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      className="card_badge "
                      data-aos="fade-up"
                      data-aos-duration="3000"
                    >
                      <div className="imag_group">
                        <img src={landing_img} className="img-fluid" />
                      </div>
                      <div className="card_section hidden">
                        {/* <p>
                          <strong>The Solalgo Group</strong> does not make the
                          mistake of forgetting the fundamentals of traditional
                          business practices that have served so well in the
                          past. However, while we employ the tried and trusted
                          methods, we also embrace and take advantage of cutting
                          edge technology to deliver a model that is success
                          driven and as bullet proof as possible.
                        </p>
                        <p>
                          <strong>The compliment</strong> to our acquisition and
                          operational approach is the latest in A.I. and machine
                          learning. Our propriety algorithm; ALGOS101- V4.2, has
                          been twenty eight months in the making and is among
                          the fastest and most efficient in the field of results
                          driven data dissemination.
                        </p>
                        <p>
                          <strong>Our management</strong> team is built on
                          excellence and experience – which all leads us to a
                          position of significant confidence in the Solalgo
                          Projects that make it to the funding stage. We know
                          from our algorithmic processes they will not only meet
                          all the requirements for commercial success but we
                          also have the additional confidence they will hugely
                          benefit from our ever increasing team of premium
                          talent.
                        </p>
                        <p>
                          <strong>
                            The <i>‘Future’</i>
                          </strong>{" "}
                          part of our tag line refers in part to our SLGO coin.
                          Unlike the vast majority of crypto coins, SLGO is a
                          currency with definable tangible value:
                        </p>
                        <p>
                          <strong>Value</strong> in the businesses we develop,
                          own and manage; (The Solalgo Group do and will
                          continue to own and exercise full executive control
                          over all its business assets now and into the future).
                        </p>
                        <p>
                          <strong>Value</strong> in how we have developed
                          considered and innovative funding, management and
                          operational systems to allow and ensure our individual
                          commercial enterprises take maximum advantage of the
                          best there is – while also providing the additional
                          benefit of driving up the value SLGO.
                        </p>
                        <p>
                          <strong>Read on</strong> and be convinced we have a
                          profitable and winning formula for the future – in a
                          platform where traditional business method enjoys all
                          the benefits of what is happening now and what is
                          going to happen in the future.
                        </p> */}
                        <p>THE SOLALGO GROUP BUSINESS MODEL</p>
                        <ul className="ul_carrr">
                          <li>
                            Merges the Past into the Present for Future Profit
                          </li>
                          <li>Develops and Employs Bullet Proof Technology</li>
                          <li>
                            Enjoys Exclusively: ALGOS101 - Our Proprietary
                            Algorithm
                          </li>
                          <li>An Inspirational, Experienced Team & Network</li>
                          <li>
                            Controlled Distribution of our Crypto Token: SLGO
                          </li>
                          <li>Defined Tokenomics</li>
                          <li>Global Reach in Identifying Purchasing Value</li>
                          <li>Innovative Crypto & Fiat Funding Strategies</li>
                          <li>Superior Business Development Knowledge</li>
                          <li>
                            A Profitable Defined End Game for Token Holders
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="Process">
            <section id="Process" className="starbg">
              <div className="container pading section_3_content ">
                <Grid container spacing={2}>
                  <h1
                    className="title_sect gradion_text"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    The Process
                  </h1>
                  {/* <p>3-5 cycles of refinement</p> */}

                  {/* <div className="container">
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            <div className="cycle_head">
                              <div className="cycle_parant">
                                <h3> The ALGOS101 Process</h3>
                                <p>3-5 cycles of refinement</p>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  {" "}
                                  Human <br />
                                  Analysis
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>ALGOS101</h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  Acquisition <br /> Report
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  {" "}
                                  Acquisition
                                  <br /> Team
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  SLGO <br /> Acquisition Fund
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  NEW <br />
                                  COMPANY
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  Profits
                                  <br />
                                  Dividends
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>
                                  Burn <br />
                                  Wallet
                                </h4>
                              </div>
                              <div className="cycle_child">
                                <h4>ALGOS101</h4>
                                <p>Initial Findings</p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        {/* <img src={require("../image/lifecycle-canva.png")} /> */}
                        <div className="progress_setrp">
                          <div className="count">1</div>
                          <div className="inner">
                            <h4>ALGOS101</h4>
                            <p>Initial Findings</p>
                          </div>
                          <p>
                            AI Search & Rank - Proprietary Algorithm - Identify,
                            Acquire, Fund, Develop
                          </p>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">2</div>
                          <div className="inner">
                            <h4>
                              Human <br />
                              Intelligence
                            </h4>
                          </div>
                          <p>
                            Programming - Analytics - Negotiation - Purchasing -
                            Management - Operational
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">3</div>
                          <div className="inner">
                            <h4>ALGOS101 Process ll</h4>
                          </div>
                          <p>
                            Progressive Tokenomics - Distribution - Trade-
                            Control - Burn - Redeem{" "}
                          </p>
                        </div>
                      </Grid>
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">4</div>
                          <div className="inner">
                            <h4>Acquisition Table</h4>
                          </div>
                          <p>
                            A % success graded table of viable aquisition
                            targets produced
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">5</div>
                          <div className="inner">
                            <h4> Acquisition Team</h4>
                          </div>
                          <p>
                            Inhouse team commence & complete 3rd party contact &
                            negotiation
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">6</div>
                          <div className="inner">
                            <h4>Establishment & Funding </h4>
                          </div>
                          <p>
                            Funding provision, incorporation, recruitment,
                            operation roll out
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">7</div>
                          <div className="inner">
                            <h4>Corporate</h4>
                          </div>
                          <p>
                            Solalgo holds a minimum of 80% of issued stock in
                            every company
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">8</div>
                          <div className="inner">
                            <h4>Mission 1</h4>
                          </div>
                          <p>Solalgo operational companies generate profit</p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="progress_setrp">
                          <div className="count">9</div>
                          <div className="inner">
                            <h4> Mission 2</h4>
                          </div>
                          <p>
                            Generated profits used to manage SLGO float &
                            liquidity
                          </p>
                        </div>
                      </Grid> */}
                      {/* <img src={require("../image/lifecycle-canva.png")} /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="count">
            <section id="count" className="starbg">
              <div className="container pading">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <div className="gradient-border" id="box">
                      <h4> 972</h4>
                      <p>businesses analysed</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <div className="gradient-border" id="box">
                      <h4> 118 </h4>
                      <p>Made it to the refinement stage</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <div className="gradient-border" id="box">
                      <h4> 21 </h4>
                      <p>Advanced to the acquisition team</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <div className="gradient-border" id="box">
                      <h4> 3 </h4>
                      <p>selected for funding</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="INVESTMENTS">
            <section id="count" className="starbg">
              <div className="container pading section_3_content">
                <h1 className="title_sect gradion_text" data-aos="fade-up">
                  ALGOS101 PRIMARY TEST RESULTS JUNE / DECEMBER 2021
                </h1>
                <h1 className="title_sect gradion_text" data-aos="fade-up">
                  Our Selected Projects
                </h1>
                {/* <p data-aos="fade-up" data-aos-duration="2000">
                  As the above graphic shows you, we have analysed over 900
                  potential investment targets for Solalgo and below are the 3
                  very exciting businesses now 100% wholly owned by The Solalgo
                  Group. We envisage that by Q1 2023 Solalgo will have 7
                  businesses within its portfolio again wholly owned.
                </p> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={12}>
                    <div
                      className="project_card projetten"
                      data-aos="zoom-in-up"
                      data-aos-duration="2000"
                    >
                      <div>
                        <img src={require("../image/vetqura.png")} />
                        <p>
                          Pet Wellness Company
                          <br /> Vetqura
                        </p>
                      </div>
                      <div>
                        <img src={require("../image/BUDZ.png")} />
                        <p>
                          Regulated Marijuana Production
                          <br /> BUDZ
                        </p>
                      </div>
                      <div>
                        <img src={require("../image/modus-trans.png")} />
                        <p>
                          Online Payments gateway
                          <br /> ModusPay
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="count">
            <section id="count" className="starbg">
              <div className="container pading section_3_content">
                <h1
                  className="title_sect gradion_text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  THE SOLALGO GROUP SECURED DEVELOPMENT PROJECTS
                </h1>
                <p data-aos="fade-up" data-aos-duration="2000">
                  The Green Exchange: Clean Energy Producer, Distributor &
                  Broker
                </p>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Cobalt Mining RC: Primary Mining Licensee & Distribution
                  Agency
                </p>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Gaming Consultancy: On-line Gaming, affiliate management&
                  Consultancy
                </p>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={12}>
                    <iframe
                      className="w-100 youtoott"
                      src="https://www.youtube.com/embed/L9OPECTMUtA"
                      title="An Introduction To The Solalgo Group. The World's First iDIB (Intelligent Digital Investment Bank)"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    {/* <video className="videoTag w-100" autoPlay loop muted>
                      <source
                        src="https://youtu.be/L9OPECTMUtA"
                        type="video/mp4"
                      />
                    </video> */}
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="count">
            <section id="count" className="starbg">
              <div className="container pading section_3_content">
                <h1
                  className="title_sect gradion_text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  THE SOLALGO GROUP MILESTONES PASSED
                </h1>
                <p data-aos="fade-up" data-aos-duration="2000">
                  We have the secret sauce in our blackbox A.I. technology by
                  way of project AlgoS101
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={12}>
                    <div
                      className="project_card"
                      data-aos="zoom-in-up"
                      data-aos-duration="2000"
                    >
                      <div>
                        <i aria-hidden="true" className="far fa-hourglass"></i>
                        <p>Time </p>
                        <span>
                          Over 20,000 person hours to date have gone into the
                          development of Solalgo{" "}
                        </span>
                      </div>
                      <div>
                        <i aria-hidden="true" className="far fa-edit"></i>

                        <p>Planning</p>
                        <span>
                          Two Years and Four Months in the Research &
                          Development Stage{" "}
                        </span>
                      </div>
                      <div>
                        <i aria-hidden="true" className="far fa-images"></i>
                        <p>Testing</p>
                        <span>
                          In excess of 4 million lines of code written to
                          Version 4.5 of ALGOS101{" "}
                        </span>
                      </div>
                      <div>
                        <i
                          aria-hidden="true"
                          className="far fa-object-group"
                        ></i>
                        <p>The Team </p>
                        <span>
                          Headhunted & Recruited from the Best Minds &
                          Performers{" "}
                        </span>
                      </div>
                      <div>
                        <i aria-hidden="true" className="far fa-square"></i>
                        <p>Binance Smart Contract</p>
                        <span>
                          The Fastest Technology, the Lowest Fees, Real Value &
                          Efficiency{" "}
                        </span>
                      </div>
                      <div>
                        <span>
                          <i aria-hidden="true" className="fa fa-usd"></i>
                          <i aria-hidden="true" className="fa fa-usd"></i>
                        </span>
                        <p>Tokenomics </p>
                        <span>
                          Designed and Implemented to Ensure Control, Liquidity
                          and Burn Reduction{" "}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="SLGO">
            <section id="Tokenomics" className="starbg">
              <div className="container pading section_3_content">
                <h1 className="title_sect gradion_text" data-aos="fade-up">
                  SLGO Tokenomics
                </h1>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Our published SLGO Tokenomics are the result of a broad and
                  in-depth analysis over two years with the primary brief being
                  how to apply our business strategies alongside the vagaries of
                  crypto currency trading that would secure the necessary time
                  for the development of group projects into a profitable
                  enterprise that will incentivise and financially benefit our
                  token holders.
                </p>
                {/* <p className="title_sect gradion_text_link">
                  <a href="https://bscscan.com/token/0x940580db429da7fa8662d66f7a4d312443f09f52" target="_blank">sc: address</a>
                </p> */}
                <Grid
                  container
                  spacing={2}
                  className="banner_chart banner_chart_new"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={12}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Chart />
                    {/* <img
                      src={require("../image/Tokenomics-pie-3.png")}
                      className="chart"
                    /> */}
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="justify-content-center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-seed.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-market.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-aqusition.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-team.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-adviser.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-presale.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-privatesale.png")}
                        className="img-fluid"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <img
                        src={require("../image/Tokenomics-pie-2-liquditiy.png")}
                        className="img-fluid"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          {/* <Section id="Tokenomics">
            <section id="Tokenomics" className="starbg">
              <div className="container pading section_3_content">
                <h1 className="title_sect gradion_text" data-aos="fade-up">
                  Why Avalanche?
                </h1>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Being super fast, iron clad secure and very low on gas fees,
                  Avalanche C-Chain was an easy choice for us but for the more
                  techie minded, here are a few more stats on why….
                </p>
                <Grid container spacing={2} className="banner_chart">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={12}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="table-responsive table_list">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Blockchain Performance</th>
                            <th scope="col">Bitcoin</th>
                            <th scope="col">Ethereum</th>
                            <th scope="col">Polkadot</th>
                            <th scope="col">Avalanche</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src={require("../image/BTC.png")}
                                className="img-fluid img_table_8"
                              />
                            </th>
                            <td>
                              <img
                                src={require("../image/eth.png")}
                                className="img-fluid img_table_8"
                              />
                            </td>
                            <td>
                              <img
                                src={require("../image/dot.png")}
                                className="img-fluid img_table_8"
                              />
                            </td>
                            <td>
                              <img
                                src={require("../image/avalanche.png")}
                                className="img-fluid img_table_8"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Transactional Throughput</th>
                            <th>7 tps</th>
                            <td>14 tps</td>
                            <td>1,500 tps</td>
                            <td>4,500 tps</td>
                          </tr>
                          <tr>
                            <th>Transactional Finality</th>
                            <th>60 min</th>
                            <td>6 min</td>
                            <td>60 sec</td>
                            <td> &#60; 2 sec</td>
                          </tr>
                          <tr>
                            <th>Energy Efficient</th>
                            <th>No; ASIC - Optimal</th>
                            <td>No; GPU - Optimal</td>
                            <td>Yes; CPU - Optimal</td>
                            <td>Yes; CPU - Optimal</td>
                          </tr>
                          <tr>
                            <th>Number Of Validators</th>
                            <th>3 Pools w / &#62; 51% hash rate</th>
                            <td>2 Pools w / &#62; 51% hash rate</td>
                            <td>&#60; 200 nodes relay Chain</td>
                            <td>Thousands of nodes</td>
                          </tr>
                          <tr>
                            <th>Sybil Production</th>
                            <th>Proof of Work</th>
                            <td>Proof of Work</td>
                            <td>Proof of Stake</td>
                            <td>Proof of Stake</td>
                          </tr>
                          <tr>
                            <th>Safety Threshold</th>
                            <th>51%</th>
                            <td>51%</td>
                            <td>33%</td>
                            <td>80% parameterised</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section> */}
          <Section id="MILESTONES">
            <section id="count" className="starbg">
              <div className="container pading section_3_content">
                <h1 className="title_sect gradion_text" data-aos="fade-up">
                  Milestones
                </h1>
                {/* <p data-aos="fade-up" data-aos-duration="2000">
                  As the above graphic shows you, we have analysed over 900
                  potential investment targets for Solalgo and below are the 3
                  very exciting businesses now 100% wholly owned by The Solalgo
                  Group. We envisage that by Q1 2023 Solalgo will have 7
                  businesses within its portfolio again wholly owned.
                </p> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="container-fluid blue-bg">
                      <div className="container">
                        <div className="timeline">
                          <div
                            className="timeline__event  animated fadeInUp delay-3s timeline__event--type1"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon ">
                              <i className="lni-cake"></i>
                            </div>
                            <div className="timeline__event__date">2022</div>
                            <div className="timeline__event__content ">
                              <div className="timeline__event__title">AUG</div>
                              <div className="timeline__event__description">
                                <p>Investment Seed Capital Raised</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp delay-2s timeline__event--type2"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-burger"></i>
                            </div>
                            <div className="timeline__event__date">2022</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">OCT</div>
                              <div className="timeline__event__description">
                                <p>
                                  The First 3 Initial Solalgo Investment
                                  Businesses Released
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp delay-1s timeline__event--type3"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-slim"></i>
                            </div>
                            <div className="timeline__event__date">2022</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">NOV</div>
                              <div className="timeline__event__description">
                                <p>
                                  Whitepaper Released TGE & Private Sale
                                  Commences
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp timeline__event--type1"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-cake"></i>
                            </div>
                            <div className="timeline__event__date">2023</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">MAR</div>
                              <div className="timeline__event__description">
                                <p>
                                  Pre Sale on Cookie Sale Pancake Swap DEX
                                  Launch
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp delay-1s timeline__event--type3"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-slim"></i>
                            </div>
                            <div className="timeline__event__date">2023</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">JUN</div>
                              <div className="timeline__event__description">
                                <p>
                                  Listings on Multiple Exchanges & Strategic
                                  Partnerships Announced
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp timeline__event--type1"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-cake"></i>
                            </div>
                            <div className="timeline__event__date">2023</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">SEP</div>
                              <div className="timeline__event__description">
                                <p>
                                  Next 3 Solalgo Acquisitions Released to the
                                  World
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp delay-1s timeline__event--type3"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-slim"></i>
                            </div>
                            <div className="timeline__event__date">2023</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">OCT</div>
                              <div className="timeline__event__description">
                                <p>Solalgo Business Exchange Market Launched</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="timeline__event animated fadeInUp timeline__event--type1"
                            data-aos="zoom-out"
                            data-aos-duration="2000"
                          >
                            <div className="timeline__event__icon">
                              <i className="lni-cake"></i>
                            </div>
                            <div className="timeline__event__date">2023</div>
                            <div className="timeline__event__content">
                              <div className="timeline__event__title">DEC</div>
                              <div className="timeline__event__description">
                                <p>Off to Mars!!</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
          <Section id="TEAM">
            <section className="starbg">
              <div className="py-5 bg-light members">
                <div className="container">
                  <h1 className="title_sect gradion_text npm_instarsss">
                    {" "}
                    The Solalgo Team
                  </h1>
                  <p
                    className="cals_teame_a"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    The Solalgo Team has been recruited from a wide network of
                    global contacts the founders have built over the last 30
                    years and constitute the brightest and best at what they do.
                  </p>
                  {/* <div className="row justify-content-center mb-3">
                    <h1 className="title_sect gradion_text">
                     
                    </h1>
                  </div> */}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/barry.jpg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className="">Barry Martin</h3>
                            <p>Co Founder/CEO </p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>6 Years in the Crypto Industry</li>
                          <li>20 Years of C-Level Experience</li>

                          <li>BA Hons Finance & Business</li>
                          <li>Serial Visionary and Entrepreneur</li>
                          <li>Former FTSE 100 Board Member</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/Barry_1066"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="https://t.me/solgoodmanalgo">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/in/barry-martin-29197511/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/dk1edit.jpg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className="">Derek Kelly</h3>
                            <p>Co Founder/CFO</p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>30 Years in the City of London</li>
                          <li>11 Fintech Main Board Listings</li>

                          <li>Masters Degree Economics</li>
                          <li>Award winning writer of Fiscal Studies</li>
                          <li>ALGOS101 Creator</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/thewreckkelly"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="http://algopolymath/">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/in/derek-kelly-114885146/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/chris_piper.jpeg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className="">Chris Piper</h3>
                            <p> CTO</p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>Multiple Successful Token Launches</li>
                          <li>Avax, Matic, Bsc & Eth Experience</li>

                          <li>Computer Science Degree</li>
                          <li>7 Years in the Crypto Space</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/SolalgoSLGO"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="https://t.me/solalgo">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/company/solalgo/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/linda.jpeg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className="">Linda Skerba</h3>
                            <p> European Comms Manager</p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>1st Degree Institute of Languages</li>
                          <li>8 Years Digital Marketing</li>
                          <li>Team Leader For Europe</li>
                          <li>Fluent in Spanish, English & Russian</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/SolalgoSLGO"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="https://t.me/solalgo">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/company/solalgo/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/dipak.jpeg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className=""> Dipak Patel</h3>
                            <p> Lead Dev</p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>Blockchain Team Leader</li>
                          <li>ALGOS101 Coder Lead Dev</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/SolalgoSLGO"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="https://t.me/solalgo">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/company/solalgo/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <div className="card_team">
                        <div className="profille_image">
                          <div className="img_section_team">
                            <img
                              src={require("../image/jmbw.jpg")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="titele_team">
                            <h3 className=""> Jade Locke</h3>
                            <p> Head of Comms</p>
                          </div>
                        </div>
                        <ul className="specifiation">
                          <li>Business Comms Degree</li>
                          <li>PR & Social Media Lead</li>
                        </ul>
                        <div className="socila_link_new">
                          <a
                            href="https://twitter.com/SolalgoSLGO"
                            target="_blank"
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                          <a href="https://t.me/solalgo">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.linkedin.com/company/solalgo/">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {/* <div className="flex-team">
                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/barry.jpg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Barry</h3>
                      <p>Crypto Strategy, Sales & Marketing</p>
                    </div>
                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-3"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/dk1edit.jpg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Derek</h3>
                      <p>Finance, Analytics, HR, Administration</p>
                    </div>
                  </div>
                  <div className="flex-team">
                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-2"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/chris.jpg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Chris</h3>
                      <p>Crypto Technology R & D</p>
                    </div>

                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-4"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/dipak.jpeg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Dipak</h3>
                      <p>ALGOS101 R & D</p>
                    </div>
                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-5"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/team-member-2.jpg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Matt</h3>
                      <p>On-Line Marketing R & D</p>
                    </div>

                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-6"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/jmbw.jpg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Jade</h3>
                      <p>Business Development & Customer Relations</p>
                    </div>

                    <div
                      className="new_team_section"
                      data-toggle="modal"
                      data-target="#myModalteam-5"
                    >
                      <div id="box" className="gradient-border">
                        <img
                          src={require("../image/ann-marie.jpeg")}
                          className="img-fluid"
                        />
                      </div>
                      <h3 className="text-center pt-4">Ann-Marie</h3>
                      <p>Social Media Development & Support</p>
                    </div>
                  </div> */}
                  <p
                    className="cals_teame_a"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    The Solalgo Group has an ongoing recruitment programme
                    designed to identify and employ the best qualified expertise
                    to the team. If you would like to join our mission to change
                    the world of banking and business and truly believe you have
                    what it takes, then email your CV to: info@solalgo.com with
                    the subject: Team
                  </p>
                </div>
              </div>
            </section>
          </Section>
          <Section className="starbg hidden">
            <section className="container">
              <div className="row justify-content-center">
                <h1 className="title_sect gradion_text">How to Buy SLGO Now</h1>
                <div className="col-lg-9">
                  <div id="main">
                    <div class="container">
                      <div class="accordion" id="faq">
                        {/* <div class="card">
                          <div class="card-header" id="faqhead1">
                            <a
                              href="#"
                              class="btn btn-header-link"
                              data-toggle="collapse"
                              data-target="#faq1"
                              aria-expanded="true"
                              aria-controls="faq1"
                            >
                              Step 1
                            </a>
                          </div>

                          <div
                            id="faq1"
                            class="collapse show"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Download Google Chrome. If you already have it,
                              skip to the next step. Here is the download link:
                              <br />
                              <a
                                href="https://www.google.com/chrome/?brand=UEAD&ds_kid=43700052784036226&utm_source=bing&utm_medium=cpc&utm_campaign=1011197%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20NA%20%7C%20US%20%7C%20en%20%7C%20Desk%20%7C%20SEM%20%7C%20BKWS%20-%20EXA%20%7C%20Txt%20~%20Top%20KWDS&utm_term=google%20chrome%20download&utm_content=Desk%20%7C%20BKWS%20-%20EXA%20%7C%20Txt%20~%20Download%20Chrome%20~%20Top%20KWDS&gclid=af529885c2301d110509faf43219d5e6&gclsrc=3p.ds"
                                target="_blank"
                              >
                                Google Chrome - Download the Fast, Secure
                                Browser from Google
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq2"
                              aria-expanded="true"
                              aria-controls="faq2"
                            >
                              Step 2
                            </a>
                          </div>

                          <div
                            id="faq2"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Remember where you downloaded Chrome on your
                              computer, and open “ChromeSetup.exe” to install
                              Chrome.Follow the installation instructions. Once
                              Chrome is installed, open it up.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead3">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq3"
                              aria-expanded="true"
                              aria-controls="faq3"
                            >
                              Step 3
                            </a>
                          </div>

                          <div
                            id="faq3"
                            class="collapse"
                            aria-labelledby="faqhead3"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Go to the website below, the Chrome Web Store and
                              download the Metamask browser extension. Follow
                              the setup instructions.
                              <p className="text-danger text-center">
                                ***IMPORTANT***
                              </p>
                              When setting up your Metamask wallet for the first
                              time, they will give you a seed/recovery phrase.
                              <u> WRITE IT DOWN </u>and keep it somewhere safe.
                              If you ever lose access to your wallet, it is the
                              only way to get access back to your wallet.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead4">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq4"
                              aria-expanded="true"
                              aria-controls="faq4"
                            >
                              Step 4
                            </a>
                          </div>

                          <div
                            id="faq4"
                            class="collapse"
                            aria-labelledby="faqhead4"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              If you’re not logged into your Metamask wallet,
                              log in. Once logged in, you should be on the
                              Ethereum Mainnet at the top of your wallet and
                              there will be a dropdown arrow. Click on the
                              dropdown arrow, click on “Add Network.” <br />
                              This should open up a new tab that takes you to a
                              Metamask page in Google Chrome for you. Enter the
                              following information, then click “Save.”
                              <ul className="pl-4">
                                <li>
                                  <b>Network Name:</b> Avalanche Network
                                </li>
                                <li>
                                  <b>New RPC URL:</b>{" "}
                                  https://api.avax.network/ext/bc/C/rpc
                                </li>
                                <li>
                                  <b>ChainID :</b>43114
                                </li>
                                <li>
                                  <b>Symbol :</b>AVAX
                                </li>
                                <li>
                                  <b>Explorer :</b>https://snowtrace.io/
                                </li>
                              </ul>
                              You should now be on the Avalanche Network. That
                              will be in the box at the top of your Metamask
                              wallet with a dropdown arrow next to it. If you
                              are not on the Avalanche Network for any reason,
                              click on the dropdown arrow and select the
                              Avalanche Network.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq5"
                              aria-expanded="true"
                              aria-controls="faq5"
                            >
                              Step 5
                            </a>
                          </div>

                          <div
                            id="faq5"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Click on the Metamask icon at the top right of
                              Google Chrome that looks like a little fox. You
                              Metamask wallet will pop up. At the bottom of your
                              wallet, you will see “import token.” Click on it.
                              Now click on the tab that says “Custom Token.”
                              Click in the first box that says “Token Contract
                              Address.” <br />
                              <br />
                              Paste in the contract address for the SLGO token
                              which is:
                              0x0CC9D203666e276D1B4BA8a6A668B96929F1c663 <br />
                              <br />
                              The rest of the boxes should fill in
                              automatically. Click on the big blue button that
                              says “Add Custom Token.” Now you have added the
                              SLGO token to your Metamask wallet. Once you
                              receive them, they will now display in your
                              wallet.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq6"
                              aria-expanded="true"
                              aria-controls="faq6"
                            >
                              Step 6
                            </a>
                          </div>

                          <div
                            id="faq6"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Go to Coinbase.com. If you don’t have an account
                              already, sign up for a new account, it’s free. You
                              can also use any other platform or exchange that
                              sells Avalanche(AVAX) and buy it through there. If
                              you are using Coinbase, proceed to follow the
                              setup instructions for creating a new account
                              <br />
                              <br />
                              Once you account is setup and verified, click on
                              your profile silhouette icon in the top right
                              corner of the page. Click on “Settings” then click
                              on the “Payment Methods” tab. Click on the big
                              blue “Add a Payment Method” button on the right.
                              <br />
                              <br />
                              Add your payment method. If you have any issues
                              with setting up your account, contact Coinbase
                              support. They may also required you to do a
                              KYC(know your customer). This is just an
                              additional layer of security for Coinbase to
                              verify your identity, and it is required by the
                              U.S. Government
                              <br />
                              <br />
                              Once your account is fully setup, now you can
                              click on the big blue “Buy/Sell” button on the top
                              right of the page. A window will pop up, make sure
                              you are on the “Buy” tab. Select the token you
                              want to purchase which is “AVAX.” Enter the amount
                              of AVAX you want to purchase either in the
                              quantity of AVAX or in $USD or your native
                              currency. Confirm that you want to proceed, you
                              may need to enter a code that Coinbase will text
                              you or email you. Retrieve the code, enter it on
                              Coinbase, and your transaction will be confirmed.
                              It usually is completed immediately
                              <br />
                              <br />
                              You can click on the “Assets” tab on the left side
                              of the screen, and you should see you AVAX balance
                              in there.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq7"
                              aria-expanded="true"
                              aria-controls="faq7"
                            >
                              Step 7
                            </a>
                          </div>

                          <div
                            id="faq7"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Now, click on the “Send/Receive” button on the top
                              of the page. Make sure you select the token you
                              want to send as “AVAX.”
                              <br />
                              <br />
                              Now click on the Metamask icon in Chrome(the fox),
                              make sure it says you are on the Avalanche
                              Mainnet. You will see at the top of the wallet
                              window, below the network dropdown box, an address
                              starting with a “0x” and what looks like two
                              overlapping pieces of paper to the right of it.
                              Click on those two pieces of paper, it should say
                              “Address Copied.”
                              <br />
                              <br />
                              Head back to Coinbase, and paste in the address
                              you copied. Verify you are sending AVAX, send all
                              of it or whatever amount you want to send, then
                              complete the transaction. You should receive the
                              AVAX from your Coinbase wallet into your Metamask
                              wallet within seconds, maybe up to 1 minute.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq8"
                              aria-expanded="true"
                              aria-controls="faq8"
                            >
                              Step 8
                            </a>
                          </div>

                          <div
                            id="faq8"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >
                            <div class="card-body">
                              Now that you have your AVAX in your Metamask
                              wallet, head over to the website below called
                              Trader Joe’{" "}
                              <a href="https://traderjoexyz.com/trade#/">
                                https://traderjoexyz.com/trade#/
                              </a>
                              <br />
                              <br />
                              You will see a “Swap” box that shows two different
                              tokens. On the top box, it should be on AVAX by
                              default. If it isn’t, click the dropdown arrow and
                              select “AVAX.”
                              <br />
                              <br />
                              For the bottom box, click on the dropdown arrow. A
                              window will pop up, in the search box, paste in
                              the SLGO contract address there which is:
                              0x0CC9D203666e276D1B4BA8a6A668B96929F1c6
                              <br />
                              <br />
                              You should see the SLGO token pop up as an option
                              for the coin you want to select. Click on it. This
                              will take you back to the “Swap” box. Now, on
                              Trader Joe’s, click on the purple box in the top
                              right corner that says “Connect Wallet.” A box
                              will pop up, click on “Metamask.”
                              <br />
                              <br />
                              Now, a Metmask window will open. Click on the blue
                              button that says “Next” then click on the big blue
                              “Connect” button. Now your wallet is connected to
                              Trader Joe’s.
                              <br />
                              <br />
                              Choose the amount of AVAX you want to swap for
                              SLGO. Click on the “Swap” button, confirm, then
                              the swap will initiate. The swap should happen
                              within a few seconds. Once it completed, you can
                              click on the Metamask icon again to open your
                              wallet, and you should now see your SLGO tokens in
                              your wallet you got from Trader Joe’s with your
                              AVAX.
                              <br />
                              <br />
                              <p className="text-center text-danger">
                                CONGRATULATIONS!!! You now own SLGO tokens
                              </p>
                            </div>
                          </div>
                        </div> */}

                        <div className="buyicon">
                          <img src={require("../image/Logo-Apple-Pay1.png")} />
                          <img
                            src={require("../image/MasterCard-Logo-1996.png")}
                          />

                          <img src={require("../image/VISA-Logo-1976.png")} />
                        </div>
                        <div className="align_ien">
                          <Button className="button_conntect_wal p-3 button_homeee">
                            <a
                              className="colo_0000"
                              href="https://sol.solalgo.com/"
                              target="_blank"
                            >
                              BUY NOW
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Section>
          <Section id="Funding">
            <section className="starbg">
              <div className="container">
                <div class="row section_3_content mt-4 mb-4">
                  <h1 className="title_sect gradion_text">
                    Looking for Funding?
                  </h1>
                  <p>
                    Have you got a business idea in need of financial
                    asstiance? Got a business that is struggling with cash flow?
                    Do you want to sell your business? If so then please{" "}
                    <a
                      href="https://forms.gle/2TCHLm2DRzYVFrw78"
                      target="_blank"
                      className="gradion_text ahref_fornee"
                    >
                      apply now
                    </a>
                    .
                  </p>
                </div>
              </div>
            </section>
          </Section>
          <Section id="CONTACT">
            <section className="starbg">
              <div class="container animated fadeIn">
                <div class="row section_3_content">
                  <p>Contact Us</p>
                  <h1 className="title_sect gradion_text">
                    Have questions? Ideas? Want to collaborate? Drop us a line.
                  </h1>
                  <hr />
                  <div class="col-sm-12 row justify-content-center" id="parent">
                    {/* <div class="col-sm-6">
                      <iframe
                        width="100%"
                        height="320px;"
                        frameborder="0"
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJaY32Qm3KWTkRuOnKfoIVZws&key=AIzaSyAf64FepFyUGZd3WFWhZzisswVx2K37RFY"
                        allowfullscreen
                      ></iframe>
                    </div> */}

                    <div class="col-sm-9">
                      <form>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            name="userName"
                            placeholder="Your name (required)"
                            required=""
                            value={userName}
                            onChange={handleChangeData}
                          />
                          {ValidateNameError && ValidateNameError == true ? (
                            <span className="text-danger">
                              {" "}
                              {validationErrors.userName}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="form-group form_left">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            placeholder="Your Email (required)"
                            required=""
                            value={email}
                            onChange={handleChangeData}
                          />
                          {ValidateEmailError && ValidateEmailError == true ? (
                            <span className="text-danger">
                              {" "}
                              {validationErrors.email}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="phone"
                            placeholder="
                            Subject"
                            name="Subject"
                            required=""
                            value={Subject}
                            onChange={handleChangeData}
                          />
                          {ValidateSubjectError &&
                          ValidateSubjectError == true ? (
                            <span className="text-danger">
                              {" "}
                              {validationErrors.Subject}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="form-group">
                          <textarea
                            class="form-control textarea-contact"
                            rows="5"
                            id="comment"
                            name="comments"
                            placeholder="Message"
                            required=""
                            value={comments}
                            onChange={handleChangeData}
                          ></textarea>
                          {ValidateCommentsError &&
                          ValidateCommentsError == true ? (
                            <span className="text-danger">
                              {" "}
                              {validationErrors.comments}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                          <br />

                          <a
                            class="btn btn-light action-button button_conntect_wal float-right"
                            onClick={() => sendMessage()}
                          >
                            Send Message!
                          </a>

                          {/* <button onClick={()=>sendMessage()}>                            
                          </button> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* <div class="container second-portion">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                      <div class="box">
                        <div class="icon">
                          <div class="image">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </div>
                          <div class="info">
                            <h3 class="title">MAIL & WEBSITE</h3>
                            <p>
                              <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                              info@solalgo.com
                            </p>
                          </div>
                        </div>
                        <div class="space"></div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-lg-4">
                      <div class="box">
                        <div class="icon">
                          <div class="image">
                            <i class="fa fa-mobile" aria-hidden="true"></i>
                          </div>
                          <div class="info">
                            <h3 class="title">CONTACT</h3>
                            <p>
                              <i class="fa fa-mobile" aria-hidden="true"></i>{" "}
                              Telegram @solalgo
                            </p>
                          </div>
                        </div>
                        <div class="space"></div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-lg-4">
                      <div class="box">
                        <div class="icon">
                          <div class="image">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          </div>
                          <div class="info">
                            <h3 class="title">ADDRESS</h3>
                            <p>
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>{" "}
                              London
                            </p>
                          </div>
                        </div>
                        <div class="space"></div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          </Section>
        </main>
        <Footer />
      </ScrollingProvider>

      <div class="modal" id="myModal">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Barry Martin
                    </h3>
                    <p id="saitateamuser" class="">
                      Co Founder/CEO
                    </p>
                    <ul className="specifiation">
                      <li>6 Years in the Crypto Industry</li>
                      <li>20 Years of C-Level Experience</li>

                      <li>BA Hons Finance & Business</li>
                      <li>Serial Visionary and Entrepreneur</li>
                      <li>Former FTSE 100 Board Member</li>
                    </ul>
                    <div className="socila_link_new">
                      <a href="https://twitter.com/Barry_1066" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="https://t.me/solgoodmanalgo">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/in/barry-martin-29197511/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalteam-2">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Chris Piper
                    </h3>
                    <p id="saitateamuser" class="">
                      CTO
                    </p>
                    <ul className="specifiation">
                      <li>Multiple Successful Token Launches</li>
                      <li>Avax, Matic, Bsc & Eth Experience</li>

                      <li>Computer Science Degree</li>
                      <li>7 Years in the Crypto Space</li>
                    </ul>
                    <div className="socila_link_new">
                      <a href="https://twitter.com/SolalgoSLGO" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="https://t.me/solalgo">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/solalgo/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalteam-3">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Derek Kelly
                    </h3>
                    <p id="saitateamuser" class="">
                      Co Founder/CFO
                    </p>
                    <ul className="specifiation">
                      <li>30 Years in the City of London</li>
                      <li>11 Fintech Main Board Listings</li>

                      <li>Masters Degree Economics</li>
                      <li>Award winning writer of Fiscal Studies</li>
                      <li>ALGOS101 Creator</li>
                    </ul>
                    <div className="socila_link_new">
                      <a
                        href="https://twitter.com/thewreckkelly"
                        target="_blank"
                      >
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="http://algopolymath/">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/in/derek-kelly-114885146/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-3"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalteam-4">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Dipak Patel
                    </h3>
                    <p id="saitateamuser" class="">
                      Lead Dev
                    </p>
                    <ul className="specifiation">
                      <li>Blockchain Team Leader</li>
                      <li>ALGOS101 Coder Lead Dev</li>
                    </ul>
                    <div className="socila_link_new">
                      <a href="https://twitter.com/SolalgoSLGO" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="https://t.me/solalgo">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/solalgo/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-4"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalteam-5">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Matt Purdy
                    </h3>
                    <p id="saitateamuser" class="">
                      CMO
                    </p>
                    <ul className="specifiation">
                      <li>4 Years in Crypto</li>
                      <li>First Degree in Marketing & Comms</li>

                      <li>Digital Marketing Diplomas</li>
                      <li>3 Previous Successful Launches</li>
                    </ul>
                    <div className="socila_link_new">
                      <a href="https://twitter.com/SolalgoSLGO" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="https://t.me/solalgo">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/solalgo/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-5"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalteam-6">
        <div class="modal-dialog modal-dialog-centered wd90">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row col-md-10 align-items-center">
                  <div class="col-md-5 saitateaminner">
                    <h3 id="saitateamtitle" class="saitagray">
                      Jade Locke
                    </h3>
                    <p id="saitateamuser" class="">
                      Head of Comms
                    </p>
                    <ul className="specifiation">
                      <li>Business Comms Degree</li>
                      <li>PR & Social Media Lead</li>
                    </ul>
                    <div className="socila_link_new">
                      <a href="https://twitter.com/SolalgoSLGO" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="https://t.me/solalgo">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/solalgo/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    id="saitateampic"
                    class="col-md-5 saitateaminnerpic saitateaminnerpic-6"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
