import React, {useEffect, useState} from "react";
import qustin from "../image/qustin.svg";
import plane from "../image/plane.svg";
import notification from "../image/notification.svg";
import meta from "../image/MetaMask_Fox 1.svg";
import coinbase from "../image/coinbase-logo-freelogovectors 1.svg";

import wallet from "../image/wallet_icon.svg";

import {Link} from "react-router-dom";
import trest from "../image/trust.svg";

import walletconnt from "../image/wallet.png";
import Logo from "../image/DiscountSales.png";
import ETH from "../image/eth.svg";
import BSE from "../image/bse.svg";
import poly from "../image/bolygon.png";

import ReactTooltip from "react-tooltip";
import MaterialDesignSwitch from "../component/Countdown";
import logoN from "../image/clola_logoo.png";
import {useTranslation} from "react-i18next";
import i18n from "../i18n";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

function Header() {
  const [currentLanguage, setcurrentLanguage] = useState("");
  const Process = useScrollSection("Process");
  const INVESTMENTS = useScrollSection("INVESTMENTS");
  const SLGO = useScrollSection("SLGO");
  const MILESTONES = useScrollSection("MILESTONES");
  const TEAM = useScrollSection("TEAM");
  const CONTACT = useScrollSection("CONTACT");
  const {t} = useTranslation();
  const options = [
    {
      label: "EN",
      value: "en",
    },
    {
      label: "RU",
      value: "ru",
    },
    {
      label: "KO",
      value: "ko",
    },
    {
      label: "CH",
      value: "zh",
    },
  ];
  const handleChange = (e) => {
    setcurrentLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };
  return (
    <div>
      <header className="container-fluid">
        <div className="container-fluid">
          <nav class="navbar navbar-light navbar-expand-md navigation-clean-search">
            <div class="container-fluid">
              <a class="navbar-brand gradion_text" href="/">
                <img src={logoN} className="" />
              </a>
              <button
                data-toggle="collapse"
                class="navbar-toggler"
                data-target="#navcol-1"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navcol-1">
                <ul class="nav navbar-nav  ml-auto">
                  <li class="nav-item" role="presentation">
                    <Link
                      to="/"
                      class="nav-link"
                      onClick={Process.onClick}
                      selected={Process.selected}
                    >
                      {t("ALGOS101")}
                    </Link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <Link
                      to=""
                      class="nav-link"
                      onClick={INVESTMENTS.onClick}
                      selected={INVESTMENTS.selected}
                    >
                      {t("INVESTMENTS")}
                    </Link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <Link
                      to=""
                      class="nav-link"
                      onClick={SLGO.onClick}
                      selected={SLGO.selected}
                    >
                      {t("SLGO")}
                    </Link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <Link
                      to=""
                      class="nav-link"
                      onClick={MILESTONES.onClick}
                      selected={MILESTONES.selected}
                    >
                      {t("MILESTONES")}
                    </Link>
                  </li>

                  <li class="nav-item" role="presentation">
                    <Link
                      to=""
                      class="nav-link"
                      onClick={TEAM.onClick}
                      selected={TEAM.selected}
                    >
                      {t("TEAM")}
                    </Link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <Link
                      to=""
                      class="nav-link"
                      onClick={CONTACT.onClick}
                      selected={CONTACT.selected}
                    >
                      {t("CONTACT")}
                    </Link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <Link to="https://sol.solalgo.com/" class="nav-link" target="_blank">
                      SOLALGO WALLET
                    </Link>
                  </li>
                  {/* <li>
                    <select value = {currentLanguage} onChange={handleChange}>
                        {options.map((option) => (
                          <option value={option.value} onChange={() => {i18n.changeLanguage(option.value)}}>{option.label}</option>
                        ))}
                    </select>
                  </li> */}
                  {/* <li class="nav-item" role="presentation">
                    <a
                      href="https://forms.gle/2TCHLm2DRzYVFrw78"
                      class="nav-link"
                      target="_blank"
                    >
                      FUNDING
                    </a>
                  </li> */}

                  {/* <li class="nav-item dropdown"><a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">More</a>
                            <div class="dropdown-menu" role="menu">
                              <Link className="dropdown-item" to="/Liquditytable">Liquidity table </Link>
                            <a class="dropdown-item" role="presentation" href="/Liquditytable" >Liquidity table </a>
                              <a class="dropdown-item" role="presentation" href="#" data-toggle="modal" data-target="#waiting">Waiting</a>
                              <a class="dropdown-item" role="presentation" href="#" data-toggle="modal" data-target="#faild">Faild</a>
                              <a class="dropdown-item" role="presentation" href="#" data-toggle="modal" data-target="#succes">Success</a>
                              </div>
                          
                        </li> */}
                </ul>

                {/* <a
                  class="btn btn-light action-button button_conntect_wal"
                  role="button"
                  href="https://ico.solalgo.com/"
                  target="_blank"
                >
                  Join Private Sale
                </a> */}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
