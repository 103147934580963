import React from "react";
import Sideber from "./Sidebar";
import Header from "./Headerafterlogin";
import HeaderAfter from "./Headerafterlogin";

import Twitter from "../image/twitter.svg";
import USD from "../image/USD.png";
import BTC from "../image/BTC.png";
import TRX from "../image/trx.png";
import BTC1 from "../image/btc_1.png";
import TUST from "../image/usdt.png";
import ETH from "../image/eth.png";
import TUSD from "../image/tusd.png";
import TUDC from "../image/usdc.png";
import landing_img from "../image/horse_1.png";
import homei_1 from "../image/home2_1.png";
import homei_2 from "../image/home2_2.png";

import homei_3 from "../image/home2_3.png";

import homei_4 from "../image/home2_4.png";
import homesectio2 from "../image/capage/D966AB20-7994-4931-8E45-8A582E3F0A77.png";
import Tokenomic from "../image/Tokenomics.png";
import Trade from "../image/Trade.png";

import lion_1 from "../image/lion_img.png";
import facetoken from "../image/tokenface.png";
import filan_icon_1 from "../image/trade_icon.png";
import filan_icon_2 from "../image/earn_icon.png";

import filan_icon_3 from "../image/arb_icon.png";
import link_i from "../image/link_i.png";
import logoN from "../image/clola_logoo.png";
import house_2 from "../image/house_2.png";

import Chart from "./chart/Chart";

import Footer from "./Footer";

import waiting from "../image/waiting.png";
import faild from "../image/faild.png";

import sucess from "../image/succes.png";

import ReactTooltip from "react-tooltip";
import MaterialDesignSwitch from "./Countdown";

import user1 from "../image/founder.png";
import dedault from "../image/dedault.png";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Scrollbars} from "react-custom-scrollbars";

import inter from "../image/internet.svg";

import Token1 from "../image/toker1.png";
import {Button} from "@material-ui/core";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function Home() {
  const addToken = async () => {
    const tokenAddress = "0xf3afb1d8b11c2eaa133511106aa03ae49296976e";
    const tokenSymbol = "CHOLA";
    const tokenDecimals = 18;
    const tokenImage =
      "https://res.cloudinary.com/dpuwta04a/image/upload/v1649527602/clola_logoo.b262654b225cac511164_cjaigc.png";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <ScrollingProvider>
        <Header />
        <main>
          <Section id="home">
            <section id="home">
              <div className="container pading con-form pt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h1 className="title_aa_heade">Contact Us</h1>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={8}>
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="">
                            Firstname<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name=""
                            placeholder="Firstname"
                            required=""
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="">Lastname</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name=""
                            placeholder="
                            Lastname"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="">Telephone</label>
                          <input
                            type="number"
                            className="form-control"
                            id=""
                            name=""
                            placeholder="Telephone"
                            required=""
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="">
                            Email <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name=""
                            placeholder="Email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label for="">
                            Question / Your inquiry <span>*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="cc_ques"
                            name="cc_ques"
                            rows="4"
                            required=""
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <Button className="btn btn-light action-button button_conntect_wal button_roee">
                            SUBMIT
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <div className="contact_mail">
                      <a href="">info@cholatoken.com</a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </Section>
        </main>
        <Footer />
      </ScrollingProvider>
      <div class="modal" id="confirm_model">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="commig_soon">
                <img src={lion_1} className="img-fluid" />
                <h1 className="gradion_text">Coming Soon</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
