import React, {useState, useEffect} from "react";
import Sideber from "./Sidebar";
import Header from "./Headerafterlogin";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Scrollbars} from "react-custom-scrollbars";

import inter from "../image/internet.svg";

import Token1 from "../image/toker1.png";
import {Button} from "@material-ui/core";

import Footer from "../component/Footer";
import {toastAlert} from "../component/toast";
const axios = require("axios");




function Home() {  
  const [euroPrice, seteuroPrice] = useState(28);
  const [usdPrice, setusdPrice] = useState(25);
  const [ValidateNameError, setValidateNameError] = useState(false);
  const [ValidateEmailError, setValidateEmailError] = useState(false);
  const [ValidateDobError, setValidateDobError] = useState(false);
  const [ValidateFiatError, setValidateFiatError] = useState(false);
  const [validationErrors, setvalidationErrors] = useState("");
  const [validateCryptoError, setValidateCryptoError] = useState(false);
  const [ValidateCurrencyError, setValidateCurrencyError] = useState(false);
  const [cryptoamount_val, setcrypto] = useState("");
  const [fiatamount_val, setfiat] = useState("");
  const [currencyval, setcurrency] = useState("USD");

  const initialFormValue = {
    name: "",
    email: "",
    dob: "",
    fiatamount: "",
    currency: "USD",
    cryptoamount: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);

  const {name, email, dob, fiatamount, currency, cryptoamount} = formValue;

  const handleChangeData = async (e) => {
    try {
      e.preventDefault();
      const {name, value} = e.target;
      let formData = {...formValue, ...{[name]: value}};
      setFormValue(formData);
      if(name=="currency")
      {
        setcurrency(value);
        setfiat("");
        setcrypto("");
      }
      if(name=="fiatamount")
      {
        var cal_val = (currencyval=="USD")? (1/25):(1/28);
        var calculation = value / cal_val;
        setcrypto(calculation);
        setfiat(value);
      }

      if(name=="cryptoamount")
      {
        var cal_val = (currencyval=="USD")? (1/25):(1/28);
        var calculation = value * cal_val;
        setfiat(calculation);
        setcrypto(value);
      }
    } catch (error) {}
  };

  const validation = async (validateName) => {
    try {
      const errors = {};
      if (!validateName.name) {
        setValidateNameError(true);
        errors.Name = "Must Enter Your Name";
      } else {
        setValidateNameError(false);
      }
      if (!validateName.email) {
        setValidateEmailError(true);
        errors.email = "Must Enter Email Address";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(validateName.email)
      ) {
        setValidateEmailError(true);
        errors.email = "Invalid Email Address";
      } else {
        setValidateEmailError(false);
      }

      if (!validateName.dob) {
        setValidateDobError(true);
        errors.dob = "Must Enter Date Of Birth";
      } else {
        setValidateDobError(false);
      }

      if (!validateName.fiatamount) {
        setValidateFiatError(true);
        errors.fiatamount = "Must Enter Your Amount";
      } else {
        setValidateFiatError(false);
      }

      if (!validateName.fiatamount) {
        setValidateFiatError(true);
        errors.fiatamount = "Must Enter Your Amount";
      } else {
        setValidateFiatError(false);
      }

      if (!validateName.currency) {
        setValidateCurrencyError(true);
        errors.currency = "Must Select Currency";
      } else {
        setValidateCurrencyError(false);
      }

      if (!validateName.cryptoamount) {
        setValidateCryptoError(true);
        errors.cryptoamount = "Must Enter Your Token";
      } else {
        setValidateCryptoError(false);
      }

      setvalidationErrors(errors);
    } catch (error) {}
  };

  const sendMessage = async () => {
    try {
      formValue.cryptoamount = (cryptoamount_val==undefined)?cryptoamount:cryptoamount_val;
      formValue.fiatamount = (fiatamount_val==undefined)?fiatamount:fiatamount_val;
      validation(formValue);
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)) {
      } else {
        console.log("formValue====",formValue);
        if (
          formValue.email != "" &&
          formValue.name != "" &&
          formValue.currency != "" && 
          formValue.dob != "" && 
          formValue.cryptoamount != "" && 
          formValue.fiatamount != ""
        ) {
          let messageSent = await axios({
            method: "post",
             url: "https://api.solalgo.com/buytoken",
            headers: {"Content-Type": "application/json"},
            data: formValue,
          });
          console.log(
            messageSent.data.status,
            "=-=-=messageSent-=-=-messageSent-=-=-messageSent"
          );
          if (messageSent.data.status) {
            toastAlert("success", messageSent.data.Message);
            setFormValue(initialFormValue);
            setcurrency("USD");
            setfiat("");
            setcrypto("");
            
          } else {
            toastAlert("error", messageSent.data.Message);
          }
        } else {
        }
      }
      console.log("---sendMessage---");
    } catch (error) {}
  };

  return (
    <ScrollingProvider>
      <div className="main_pading">
        <Header />

        <section className="craeteoption starbg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="titile-ico">
                  <img
                    src={require("../image/clola_logoo.png")}
                    className="ico-logo"
                  />
                  <h1 className="gradion_text">THE SOLALGO GROUP </h1>
                  <h4>SLGO COIN</h4>
                  <h5>MANAGED SERVICES </h5>
                  <p>
                    The Innovative Solalgo Managed Service takes away all the
                    mystery and difficulty of entry into crypto investment and
                    allows you to become part of the new world of crypto for as
                    low as a €100 investment.
                  </p>
                </div>
              </div>
            </div>

            <div className="row align-items-center mt-5 mb-5">
              <div className="col-lg-6">
                <div className="list-service">
                  <h2>The Solalgo Group SLGO Managed Service </h2>
                  <ul>
                    <li>
                      A Fully Secure Managed Crypto Wallet set up by Solalgo on
                      your behalf
                    </li>
                    <li>
                      A Simple Buy and Sell Facility through Email, Whatsapp or
                      SMS
                    </li>

                    <li>Secure International Bank Transfer of Funds</li>

                    <li>A Monthly Statement to Your Email</li>

                    <li>
                      A Dedicated App for Real Time Price Checks on Your Phone,
                      Tablet or Laptop
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_badge ">
                  <div className="card_section">
                    <div className="row">
                      <div className="col-lg-12">
                        <p>
                          To avail of The Solalgo Group SLGO Managed Service{" "}
                          <br />
                          Please complete this form
                        </p>
                        <form className="ico-form">
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="inputEmail4">Name</label>
                              <input
                                type="text"
                                name="name"
                                class="form-control"
                                id="inputEmail4"
                                placeholder="name"
                                value={name}
                                onChange={handleChangeData}
                              />
                              {ValidateNameError && ValidateNameError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.Name}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="form-group col-md-6">
                              <label for="inputPassword4">Date Of Birth</label>
                              <input
                                type="date"
                                name="dob"
                                class="form-control"
                                id="inputPassword4"
                                placeholder="Date Of Birth"
                                value={dob}
                                onChange={handleChangeData}
                              />
                              {ValidateDobError && ValidateDobError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.dob}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="inputAddress">Email</label>
                            <input
                              type="email"
                              name="email"
                              class="form-control"
                              id="inputAddress"
                              placeholder="Email"
                              value={email}
                              onChange={handleChangeData}
                            />
                            {ValidateEmailError && ValidateEmailError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.email}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                          </div>

                          <div class="form-row">
                            <div class="form-group col-md-8">
                              <label for="inputCity">Amount You Pay</label>
                              <input
                                type="text"
                                name="fiatamount"
                                class="form-control"
                                id="inputCity"
                                placeholder="Amount You Pay"
                                value={fiatamount_val}
                                onChange={handleChangeData}
                              />
                              {ValidateFiatError && ValidateFiatError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.fiatamount}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="form-group col-md-4">
                              <label for="inputState">Currency</label>
                              <select id="inputState" class="form-control" name="currency" onChange={handleChangeData}>
                                <option selected>USD</option>
                                <option>EURO</option>
                              </select>
                              {ValidateCurrencyError && ValidateCurrencyError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.currency}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="inputAddress">Token You Get</label>
                            <input
                              type="text"
                              name="cryptoamount"
                              class="form-control"
                              id="inputAddress"
                              placeholder="Token You Get"
                              value={cryptoamount_val}
                              onChange={handleChangeData}
                            />
                            {validateCryptoError && validateCryptoError == true ? (
                              <span className="text-danger">
                                {" "}
                                {validationErrors.cryptoamount}{" "}
                              </span>
                              ) : (
                                ""
                              )}
                          </div>

                          {/* <button class="btn btn-primary " onClick={() => sendMessage()}>
                            Submit
                          </button> */}
                          <a
                            class="btn btn-primary"
                            onClick={() => sendMessage()}
                          >
                            Send Message!
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-lg-12">
                <div className="list-service">
                  <h2 className="">
                    IMMEDIATELY UPON RECIEPT OF CLEARED FUNDS THE SOLALGO GROUP
                    WILL:
                  </h2>
                  <ul>
                    <li>
                      Acknowledge your order by email and set up your crypto
                      wallet with purchased coins deposited
                    </li>
                    <li>
                      Confirm the details of your wallet and coin balance by
                      email within 24 hours
                    </li>

                    <li>
                      Provide you with secure procedures of how to sell SLGO
                      coin as well as how to buy more coin for your wallet
                    </li>

                    <li>
                      Send you- via email - a monthly statement of your coin
                      balance and related tutorials on trading crypto.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </ScrollingProvider>
  );
}

export default Home;
