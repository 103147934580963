import React from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Doughnut} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "SEED",
    "TEAM",
    "ADVISORS",
    "MARKETING",
    "ACQUISITION",
    "PRIVATE SALE",
    "PRE SALE / IDO ",
    "LIQUIDITY POOL",
  ],
  datasets: [
    {
      label: "# of Votes",
      data: [33, 10, 10, 12.5, 25, 4, 3, 2.5],
      backgroundColor: [
        "#ff5e10",
        "#e60042",
        "#b8005b",
        "#0099dd",
        "#006abc",
        "#7b0066",
        "#34005e",
        "#2c3a92",
      ],
      borderColor: [
        "#ff5e10",
        "#e60042",
        "#b8005b",
        "#0099dd",
        "#006abc",
        "#7b0066",
        "#34005e",
        "#2c3a92",
      ],
      borderWidth: 1,
    },
  ],
};

function Chart() {
  return (
    <div className="chart_widyth">
      {" "}
      <Doughnut data={data} />
    </div>
  );
}

export default Chart;
