import React from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import logoN from "../image/clola_logoo.png";
import white from "../image/pdf/Solalgowhite.pdf";
import {Button} from "@material-ui/core";
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";

function Footer() {
  const homeSection = useScrollSection("home");
  const aboutSection = useScrollSection("about");
  const Launchpad = useScrollSection("Launchpad");
  const Roadmap = useScrollSection("Roadmap");
  const Mission = useScrollSection("Mission");

  return (
    <div>
      <div className="footer">
        <div id="footer">
          <div class="saitafooter">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <a
                    class="saitaeco-icon pdTop40 mgBottom25 scroll-link"
                    href="#saitahome"
                  >
                    <img src={logoN} className="logo" />
                  </a>
                  <div class="description ">
                    <div class="col-md-12 margin-auto mgBottom25 justify-content-center d-flex">
                      <a
                        class="saitasocialicons-footer"
                        href="https://t.me/solago_slgo"
                        target="_blank"
                      >
                        <i class="fab fa-telegram fa-2x"></i>
                      </a>
                      {/* <a class="saitasocialicons-footer" href="">
                        <i class="fab fa-facebook fa-2x"></i>
                      </a>
                      <a class="saitasocialicons-footer" href="">
                        <i class="fab fa-instagram fa-2x"></i>
                      </a> */}
                      <a
                        class="saitasocialicons-footer"
                        href="https://twitter.com/SolalgoSLGO"
                        target="_blank"
                      >
                        <i class="fab fa-twitter fa-2x"></i>
                      </a>
                      <a
                        class="saitasocialicons-footer"
                        href="https://discord.gg/TBpAUJbG"
                        target="_blank"
                      >
                        <i class="fab fa-discord fa-2x"></i>
                      </a>
                      <a
                        class="saitasocialicons-footer"
                        href="https://medium.com/@solalgo"
                        target="_blank"
                      >
                        <i class="fab fa-medium fa-2x"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="description mgBottom40">
                    <h5 class="footerlinkhead">Useful Links</h5>
                  </div>
                  <div class="row txtleft footerlinks">
                    <div class="col-md-8 footer_flex">
                      <a class="scroll-link" href="https://sol.solalgo.com/" target="_blank">
                        BUY
                      </a>
                      <a class="scroll-link" href="#MILESTONES">
                      MILESTONES
                      </a>
                      {/* <a class="scroll-link" href="#saitaecosystem">
                        ECOSYSTEM
                      </a> */}

                      <a class="scroll-link" href={white} target="_blank">
                        WHITEPAPER
                      </a>
                    </div>
                    <div class="col-md-4 footer_flex">
                      <a class="scroll-link" href="#Mission">
                        MISSION
                      </a>
                      {/* <a class="scroll-link" href="#saitatransparency1">
                        TRANSPARENCY
                      </a>
                      <a class="scroll-link" href="#saitacommunity">
                        COMMUNITY
                      </a> */}
                      <a class="scroll-link" href="#CONTACT">
                        CONTACT
                      </a>
                      {/* <a
                        class=""
                        href="https://saitamatoken.com/pdf/Listing-Agreement-SAITAMA.LLC.pdf"
                      >
                        SAITAMASK LISTING AGREEMENT
                      </a> */}
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-4">
                  <div class="description "></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <footer className="container-fluid">
          <div className="footer_padin">
            {/* <img src={logoN} className="logo" /> */}
            <p>
              ©{" "}
              <a href="" target="_blank">
                info@solalgo.com
              </a>
              , All Rights Reserved
            </p>
            {/* <Grid container spacing={2} justify="space-around">
              <div className="footer_clas_flex">
                <div>
                  <div className="log_section">
                    
                    <p>Real Estate Platform</p>
                    <p>
                      &copy;{" "}
                      <a href="mailto:info@cholatoken.com" target="_blank">
                        info@cholatoken.com
                      </a>
                      , All Rights Reserved
                    </p>
                  </div>
                </div>
                <div>
                  <div className="link_footer">
                    <label>Home</label>
                    <ul>
                      <li>
                        <a href="https://ico.cholatoken.com/" target="_blank">
                          Open App
                        </a>
                      </li>

                      <li>
                        <Link
                          className=""
                          to=""
                          onClick={aboutSection.onClick}
                          selected={aboutSection.selected}
                        >
                          Tokenomics
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className="link_footer">
                    <label>Learn</label>
                    <ul>
                      <li>
                        <Link
                          className=""
                          to=""
                          onClick={Mission.onClick}
                          selected={Mission.selected}
                        >
                          Mission
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=""
                          to=""
                          onClick={Roadmap.onClick}
                          selected={Roadmap.selected}
                        >
                          Roadmap
                        </Link>
                      </li>
                      <li>
                        <a
                          href={Privacy}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li>
                        <a
                          href={Terms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                      </li>
                      <li>
                        <a
                          href={Refund}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Refund policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className="link_footer">
                    <label>Social Media</label>
                    <ul>
                      <li>
                        <a
                          className=""
                          href="https://www.facebook.com/profile.php?id=100077354993925"
                          target="_blank"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          target="_blank"
                          href="https://www.instagram.com/cholatoken/"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://twitter.com/cholatoken"
                          target="_blank"
                        >
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://t.me/+m4bdvzzSEpY3ZDU1"
                          target="_blank"
                        >
                          Telegram channel
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://t.me/+LlnczNcrn_U0Mzc1"
                          target="_blank"
                        >
                          Telegram Group
                        </a>
                      </li>
                      <li>
                        <Link className="" to="">
                          Linkedin
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className="link_footer">
                    <label>Social Media</label>
                    <ul>
                      <li>
                        <a
                          className=""
                          href="https://www.linkedin.com/in/siva-c-781062204/"
                          target="_blank"
                        >
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://www.reddit.com/u/cholatoken/?utm_source=share&utm_medium=ios_app&utm_name=iossmf"
                          target="_blank"
                        >
                          Reddit
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://discord.gg/Xft4H8tx"
                          target="_blank"
                        >
                          Discord
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href="https://youtube.com/channel/UCeD3F2HI-8FZzqqyhicqobA"
                          target="_blank"
                        >
                          Youtube
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Grid> */}
          </div>

          {/* <p className="Disclaimer">
            <span>Disclaimer:</span> Purchasing of cryptocurrencies are subject
            to market, demand, technical and regulatory risks. The prices
            fluctuate based on market demand and supply.
          </p> */}
        </footer>
      </div>
    </div>
  );
}

export default Footer;
